<template>
    <v-list-item-content>
        <v-list-item-title>
        <template v-if="link">
            <span><router-link :to="{ name: 'realm-user-view', params: { accountId, realmId, userId: attr.id } }">{{ email }}</router-link></span>
        </template>
        <template v-if="!link">
            <span>{{ email }}</span>
        </template>
        </v-list-item-title>
        <v-list-item-subtitle>
        <span class="text-caption">{{ id }}</span>
        </v-list-item-subtitle>
    </v-list-item-content>
</template>

<script>
export default {

    props: {
        // when true, the item is a link to the user view
        link: {
            type: Boolean,
            default: false,
        },
        accountId: {
            type: String,
        },
        realmId: {
            type: String,
        },
        attr: {
            type: Object,
        },
    },

    computed: {
        id() {
            return this.attr.id;
        },
        email() {
            return this.attr.email;
        },
    },

};
</script>
