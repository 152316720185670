<template>
    <v-row no-gutters>
        <v-col cols="12">
            <RealmBar :accountId="accountId" :realm="realm" class="mb-6" v-if="realm"></RealmBar>
            <AccessDeniedOverlay v-if="forbiddenError"></AccessDeniedOverlay>
            <!-- hierarchical navigation -->
            <!-- <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'user-dashboard', params: { realm: this.$route.params.realmId } }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-account-list', params: { realm: this.$route.params.realmId } }">Accounts</router-link>
                    <router-link :to="{ name: 'realm-dashboard', params: { accountId: this.$route.params.accountId, realmId: this.$route.params.realmId } }">Realm</router-link>
                </p>
                </v-col>
            </v-row> -->
            <v-row justify="center" class="py-5 px-10" v-if="realm">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card>
                        <v-toolbar color="indigo" dark flat dense>
                        <!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->
                        <v-toolbar-title>
                            Realm users
                        </v-toolbar-title>
                        </v-toolbar>
                        <RealmUserList :list="list" :accountId="accountId" :realmId="realmId"></RealmUserList>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>
<script>
import AccessDeniedOverlay from '@/components/AccessDeniedOverlay.vue';
import RealmBar from '@/components/RealmBar.vue';
import RealmUserList from '@/components/realm-dashboard/RealmUserList.vue';

export default {
    components: {
        RealmUserList,
        AccessDeniedOverlay,
        RealmBar,
    },
    data: () => ({
        realm: null,
        forbiddenError: false,
        // list user
        list: [],
        error: null,
    }),
    computed: {
        accountId() {
            return this.$route.params.accountId;
        },
        realmId() {
            return this.$route.params.realmId;
        },
    },
    methods: {
        async loadRealm() {
            try {
                this.$store.commit('loading', { loadRealm: true });
                const result = await this.$client.account(this.$route.params.accountId).realm.get({ id: this.$route.params.realmId });
                console.log(`realm/loginshield.vue loadRealm result: ${JSON.stringify(result)}`);
                if (result) {
                    this.realm = result;
                }
            } catch (err) {
                console.error('realm/loginshield.vue loadRealm failed', err);
                const message = err.message ? err.message : null;
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to load realm', message });
            } finally {
                this.$store.commit('loading', { loadRealm: false });
            }
        },
        async loadUserList() {
            const match = { account_id: this.$route.params.accountId, realm_id: this.$route.params.realmId };
            const result = await this.$client.account(this.$route.params.accountId).realmUser.search(match);
            console.log(`User.vue init result: ${JSON.stringify(result)}`);
            if (result && result.list) {
                this.list = result.list;
            }
        },
    },
    mounted() {
        this.loadRealm();
        this.loadUserList();
    },
};
</script>
